<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text>
        <!-- GOOD STUFF START -->
        <v-data-table :headers="appsHeaders" :items="apps" dense>
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="py-0">
                <td class="py-0">{{item.name}}</td>
                <td class="py-0">
                  <v-switch
                    v-model="item.enabled"
                    :disabled="!item.app_configured"
                    @change="updateApp(item, item.enabled)"
                  ></v-switch>
                </td>
                <td class="py-0 text-right">
                  <v-btn
                    color="primary"
                    small
                    v-if="!item.app_configured"
                    @click="openAddToBusinessDialog(item, 'add')"
                  >
                    <v-icon left>add</v-icon>Add
                  </v-btn>
                  <v-btn
                    color="primary"
                    outlined
                    small
                    v-else
                    @click="openConfigureDialog(item, 'update')"
                  >
                    <v-icon left>edit</v-icon>Edit
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <!-- GOOD STUFF END -->
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="addToBusinessDialog" width="600">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="pa-0" cols="auto">
              <v-icon color="darkGrey--text text-lighten-1" class="mt-2 mr-3">apps</v-icon>
            </v-col>
            <v-col class="pa-0" cols="auto">{{selectedBusinessApp.name}}</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="addToBusinessForm">
            <v-autocomplete
              :rules="rules"
              required
              v-model="selectedBusiness"
              :items="businesses"
              label="Choose a business"
              cache-items
              item-text="name"
              auto-select-first
              return-object
            ></v-autocomplete>
            <p class="ma-0 my-4 subtitle-1" primary-title>Choose an app provider</p>
            <v-row>
              <v-btn
                @click="selectProvider(item)"
                class="ml-2"
                v-for="(item, index) in selectedAppProviders"
                :color="selectedProvider.display_name === item.display_name?'primary': 'grey white--text'"
                :key="index"
                small
              >{{item.display_name}}</v-btn>
              <v-btn
                flat
                elevation="0"
                outlined
                @click="selectOtherProvider() ;selectedProvider.display_name = 'Other'"
                class="ml-2"
                small
                :color="selectedProvider.display_name === 'Other'? 'primary': 'grey white--text'"
              >Other</v-btn>
            </v-row>
            <div v-if="selectedProvider.display_name === 'Other'">
              <v-text-field
                :rules="rules"
                v-model="addToBusiness.api_url"
                flat
                name="api_url"
                label="API Url"
              ></v-text-field>
              <v-text-field
                :rules="rules"
                v-model="addToBusiness.admin_url"
                flat
                name="admin_url"
                label="Admin Url"
              ></v-text-field>
              <v-row v-for="(item, index) in apiHeaders" :key="index">
                <v-col class="py-0" cols="12" md="5">
                  <v-text-field :rules="rules" v-model="item.header_key" flat label="Header Key"></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="4">
                  <v-text-field :rules="rules" v-model="item.value" flat label="Header Value"></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="3">
                  <v-layout row wrap align-baseline>
                    <v-btn
                      @click="addHeader"
                      color="primary"
                      elevation="0"
                      fab
                      x-small
                      class="mt-3"
                    >
                      <v-icon x-small>add</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="apiHeaders.length <= 1"
                      @click="removeHeader(index, item.header_key)"
                      color="primary"
                      elevation="0"
                      fab
                      x-small
                      class="ml-7 mt-3"
                    >
                      <v-icon x-small>remove</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeAddToBusinessDialog" color="grey" text class="text-capitalize">Cancel</v-btn>
          <v-btn @click="confirm" elevation="0" color="primary" class="text-capitalize">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApplicationsAPI } from '@/clients/permissions/apps.js'
import { AuthAPI } from '@/clients/auth/auth.api'

export default {
  data() {
    return {
      apps: [],
      businesses: [],
      process: '',
      appsHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Available', value: 'enabled' },
        { text: 'Settings', value: 'app_configured', align: 'right' }
      ],
      configureDialog: false,
      addToBusinessDialog: false,
      selectedBusinessApp: {},
      selectedAppProviders: [],
      selectedProvider: {
        display_name: 'Other'
      },
      selectedBusiness: {},
      addToBusiness: {
        status: false,
        api_url: '',
        admin_url: '',
        api_headers: {}
      },
      apiHeaders: [{ header_key: '', value: '' }],
      apiHeaderKeys: [],
      rules: [v => !!v || 'Required.']
    }
  },
  computed: {
    businessId() {
      return this.$route.params.id
    }
  },
  async mounted() {
    this.spinner(true)
    await this.getAdminApps()
    await this.getAllBusinesses()
    this.spinner(false)
  },
  methods: {
    async getAdminApps() {
      let response
      try {
        response = await ApplicationsAPI.getAdminAppsForBusiness(
          this.businessId
        )
        this.apps = response.data.payload
      } catch (error) {
        console.log(error)
      }
    },

    async getAllBusinesses() {
      let response
      try {
        response = await AuthAPI.getAllBusinesses()
      } catch (error) {
        return
      }
      this.businesses = response.data.payload
    },

    addHeader() {
      this.apiHeaders.push({ header_key: '', value: '' })
    },
    removeHeader(index, key) {
      this.apiHeaders.splice(index, 1)
      this.apiHeaderKeys.splice(index, 1)
      console.log(key)
      delete this.addToBusiness.api_headers[key]
    },
    selectProvider(provider) {
      this.apiHeaders = [{ header_key: '', value: '' }]
      this.addToBusiness.admin_url = ''
      this.addToBusiness.api_url = ''
      if (provider.admin_url) {
        this.addToBusiness.admin_url = provider.admin_url
      }
      if (provider.api_url) {
        this.addToBusiness.api_url = provider.api_url
      }
      if (Object.keys(provider.api_headers).length > 0) {
        Object.keys(provider.api_headers).forEach(key => {
          this.apiHeaders = []
          this.apiHeaders.push({
            header_key: key,
            value: provider.api_headers[key]
          })
        })
      }
      this.selectedProvider.display_name = provider.display_name
    },
    selectOtherProvider() {
      this.$refs['addToBusinessForm'].resetValidation()
      this.apiHeaders = [{ header_key: '', value: '' }]
      this.addToBusiness.admin_url = ''
      this.addToBusiness.api_url = ''
    },
    async openConfigureDialog(app, process) {
      this.spinner(true)
      this.process = process
      let response
      this.selectedBusinessApp = app
      response = await ApplicationsAPI.getBusinessAppDetails(
        app.business_app_id
      )
      console.log(response)
      this.addToBusiness = response.data.payload
      this.selectedBusiness = this.businesses.find(business =>
        business.id.match(response.data.payload.business_uuid)
      )
      this.apiHeaders = []

      Object.keys(response.data.payload.api_headers).forEach(key => {
        this.apiHeaders.push({
          header_key: key,
          value: response.data.payload.api_headers[key]
        })
      })
      if (response.data.payload.api_headers.length === 0) {
        this.apiHeaders.push({ header_key: '', value: '' })
        this.addToBusiness.api_headers = {}
      }
      this.apiHeaderKeys = this.apiHeaders.map(el => el.header_key)

      this.addToBusinessDialog = true
      this.spinner(false)
    },
    async openAddToBusinessDialog(app, process) {
      let resp
      try {
        resp = await ApplicationsAPI.getAppProviders(app.id)
        this.selectedAppProviders = resp.data.payload
        if (this.selectedAppProviders.length > 0) {
          this.selectProvider(this.selectedAppProviders[0])
        }
      } catch (error) {
        if (!this._isSuperUser()) {
          this.addToBusinessDialog = false
        }
        console.log(error)
      }
      this.process = process
      this.selectedBusinessApp = app
      this.addToBusinessDialog = true
      this.apiHeaderKeys = this.apiHeaders.map(el => el.header_key)
      let selectedBusiness = this.businesses.find(i => i.id === this.businessId)
      this.selectedBusiness = selectedBusiness
    },
    closeAddToBusinessDialog() {
      this.$refs.addToBusinessForm.reset()
      this.addToBusinessDialog = false
      this.apiHeaders = [{ header_key: '', value: '' }]
    },
    async updateApp(app, active = true) {
      let response
      try {
        response = await ApplicationsAPI.getBusinessAppDetails(
          app.business_app_id
        )
        this.addToBusiness = response.data.payload
        await this.updateAppDetails(active)
      } catch (error) {
        let index = this.$_.findIndex(this.apps, { id: app.id })
        console.log(error.response)
        this.apps[index].enabled = !active
      }
    },
    async updateAppDetails(active = true) {
      //   this.apiHeaders.forEach(el => {
      //     this.addToBusiness.api_headers[el.header_key] = el.value
      //   })
      let { environment, admin_url, api_url, api_headers } = this.addToBusiness
      let data = { environment, admin_url, api_url, api_headers, active }
      //   try {
      await ApplicationsAPI.updateBusinessAppDetails(
        this.addToBusiness.business_uuid,
        this.addToBusiness.id,
        data
      )
      //   } catch (error) {
      //     console.log(error)
      //   }
    },
    async confirm(app) {
      this.spinner(true)
      if (this.$refs.addToBusinessForm.validate()) {
        this.addToBusiness.app_id = this.selectedBusinessApp.id
        this.apiHeaders.forEach(el => {
          this.addToBusiness.api_headers[el.header_key] = el.value
        })
        let response
        this.spinner(true)
        try {
          if (this.process === 'add') {
            response = await ApplicationsAPI.addAnAppToBusiness(
              this.addToBusiness,
              this.selectedBusiness.id
            )
          } else if (this.process === 'update') {
            await this.updateAppDetails()
          }
          await this.getAdminApps()
        } catch (error) {
          this.spinner(false)
          return
        }
        this.swal({
          title: 'Success',
          text: 'Application successfully added to the business',
          type: 'success'
        })
        this.addToBusinessDialog = false
        this.spinner(false)
        this.addToBusiness = {
          status: false,
          environment: '',
          api_url: '',
          admin_url: '',
          api_headers: {}
        }
      }
      this.spinner(false)
    }
  }
}
</script>

<style>
</style>
